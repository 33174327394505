.dropdownopen {
  width: 200px;
  background-color: #f0f0f0;
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease;
}

.option{
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  padding: 0px 20px;
}
.option:hover {
  background: #dddddd !important;
  cursor: pointer;
}

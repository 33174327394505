.Schedule-heading h1{
    color: #333;
    padding-bottom: 10px;
    line-height: 1em;
    font-weight: 700;
    font-size: 30px !important;
}

/* Schedule.css */

/* Hide the desktop content on mobile devices */
.desktop1 {
    display: block; /* Default display */
  }
  
  .Mobile {
    display: none; /* Default hidden */
  }
  
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .desktop1 {
      display: none; /* Hide on mobile */
    }
    .Mobile {
      display: block; /* Show on mobile */
    }
  }
  .schedule-test p{
    /* , fontSize:"13px", fontWeight:"600"  */
    font-size: 13px;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .schedule-test p{
    margin-bottom: 1px;
  }
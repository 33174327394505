.navbar {
  position: absolute;
  width: 100%;
  top: 0px;
}

.header-text-top{
    position: absolute;
    top: 170px;
    left: 5%;

}
.toggle-button{
    border: 0;
    background: white;
    margin-right: 10px;
}

.nav-padding{
    padding: 2rem 5rem;
}
.flexEnd{
    display: flex;
    justify-content: end;
}

.logo {
  height: 100px;
  width: 100px;

}
.logo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.bg-image {
  min-height: 751px;
}

.bg-image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.nav-link{
    color: #0418EE !important;
    font-family: var(--font-family);
    font-weight: bold;
}

.contact-button{
    background:#3A47BC; 
    border:0px; 
    color:white;
    border-radius:20px;
    padding:5px 15px;
    font-family: var(--font-family);


}

.bg-image-Mob img{
    height:100%;
    width:100%;
    object-fit: contain;
}
.header-mb{
     position: absolute; 
     z-index: 99999999;
     top:0;
     width:'100%'
}
.nav-link{
    padding-top:5px;
}
.nav-link:nth-child(4){
    padding-top: 0px !important;
}




@media (min-width: 601px) and (max-width: 2100px) {
  .Customised-Stsection{
    margin-bottom: -50px;
}

}
.header-text-top p{
  font-size: 18px !important;
}
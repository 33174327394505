.contactfoem_input{
    border: 1px solid #D4D8D8;
    border-radius:10px;

}


.border-bg-sky{
    border-radius: 0px 10px 0px 10px;
}


h1{
font-family:var(--font-family) ;
font-weight: bold ;
font-size: 52px ;
}


h2{
    font-family:var(--font-family);
    font-weight: bold ;
    }
p{
    font-family:var(--font-family);
    font-weight: bold;
    font-size: 16px;

}

.purple-button{
    background:#3A47BC;
    border:2px solid white;
    color:white;
    border-radius:10px;
    padding:10px 30px;
}

.red{
    color:red;
}
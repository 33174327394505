:root{
    --font-family:"Raleway", sans-serif;
    

}
.Transport-Management-col1 p{
    font-size: 13px !important;
    line-height: 20px !important;
} 
.Customised-Stsection h1{
    font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif;
    font-weight: 700;
    font-size: 32px;

}
.Health-and-Safety-Mob h1{
    font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif;
    font-weight: 700;
    font-size: 32px;
    padding-top: 30px;

}
@media screen and (min-width: 600px) {
    .Different-Stsection .col-sm-6{
        padding: 10px 10px 10px 10px !important;
    }
}
@media screen and (max-width: 600px) {
    
    .Your-Futures-Partner-Mob  h1 {
        font-family: var(--font-family);
        font-weight: bold;
        font-size: 36px;
    }
  
     .Trainings-We-Offer-Stsection .Trainings-We-Offer-col {
        border-radius: 50px 0 50px 0;
        overflow: hidden;
        padding: 20px 30px 20px 30px;
        box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.3);
        width: 100% !important;
        margin-bottom: 5%;
        text-align: center;
    }
    .Trainings-We-Offer-Stsection  .ase{ 
        display: "flex";
        paddingTop: "40px";
        }
   
    .Your-Futures-Partner{
        display: none;
    }
    /* .Health-and-Safety{
        display: none;
    } */

    .What-We-Offer-Stsection{
        display: none;
    }
    .Your-Futures-Partner{
        display: none;
    }
    .Transport-Management-section-Mob{
        background-image: url('assets/images/Group-72.png');
      background-size: auto;
      
    }
    
    .Transport-Management-section-Mob .Transport-Management-col{
        border-radius: 0 0 50px 0;
        overflow: hidden;
        box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.3);
       
    }
    
    .Transport-Management-section-Mob .Transport-Management-col p{
        padding: 20px 17px 0px 17px;
    }
    .mob-col{
        padding: 20px 0px 20px 0px;
    }
    .days1{
        margin-left: auto;
        font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif;
        font-weight: 700;
        line-height: 23.8px;
        background-color: #3a47bc;
        position: relative;
        border-radius: 19px 19px 19px 19px;
        overflow: hidden;
        padding-top: 1px !important;
        padding-right: 3px !important;
        padding-bottom: 1px !important;
        padding-left: 3px !important;
        width: 61px;
        color: #fff;
     
        margin-bottom: 17px;
    }
    .days{
    

        font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif;
        font-weight: 700;
        line-height: 23.8px;
        background-color: #3a47bc;
        position: relative;
        border-radius: 19px 19px 19px 19px;
        overflow: hidden;
        padding-top: 1px !important;
        padding-right: 3px !important;
        padding-bottom: 1px !important;
        padding-left: 3px !important;
        width: 61px;
        color: #fff;
        margin-left:auto;
        margin-bottom: 17px;
         
    }
  }
  @media (min-width: 601px) and (max-width: 2100px) {
    .Different-Stsection .col-About{
        padding: 110px 30px 40px 70px !important;
    }

    .Customised-Stsection h1{
        font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif;
        font-weight: 700;
        font-size: 44px;
    
    }
    .Different-Stsection-Mob{
      display: none;
    }
    .Trainings-We-Offer-Stsection-Mob{
        display: none;
    }
    .Health-and-Safety-Mob{
        display: none;
    }
 
    .Your-Futures-Partner-Mob{
        display: none;
    }
    .Transport-Management-section-Mob{
        display: none;
    }

    .Your-Futures-Partner-Mob{
        display: none;
}
    .Transport-Management-section{
        background-image: url('assets/images/Group-72.png');
      background-size: cover;
      
    }

    
    .Transport-Management-section .Transport-Management-col{
        border-radius: 0 0 50px 0;
        overflow: hidden;
        box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.3);
        width: 22.75%;
        margin-right: 3%;
         
    }
    
    .Transport-Management-section .Transport-Management-col p{
        padding: 20px 17px 0px 17px;
        height: 175px;
    }
    .Transport-Management-section h4{
        height: 40px;
        padding-top: auto;

    }
.ase{
    display: flex;
}
.days1{
    

    font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif;
    font-weight: 700;
    line-height: 23.8px;
    background-color: #3a47bc;
    position: relative;
    border-radius: 19px 19px 19px 19px;
    overflow: hidden;
    padding-top: 1px !important;
    padding-right: 3px !important;
    padding-bottom: 1px !important;
    padding-left: 3px !important;
    width: 61px;
    color: #fff;
    margin-left: 535px !important;
    margin-bottom: 17px;
     
}
.days{
    

    font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif;
    font-weight: 700;
    line-height: 23.8px;
    background-color: #3a47bc;
    position: relative;
    border-radius: 19px 19px 19px 19px;
    overflow: hidden;
    padding-top: 1px !important;
    padding-right: 3px !important;
    padding-bottom: 1px !important;
    padding-left: 3px !important;
    width: 61px;
    color: #fff;
    margin-left:auto;
    margin-bottom: 17px;
     
}
  }







.Customised-Stsection-Mob h1{
	font-size: 30px !important;
}

.Customised-Stsection-Mob p{
	font-size: 14px;
	line-height: 20px;
}

.Different-Stsection{
    background-image: url('assets/images/Group-72.png');
  background-size: cover;
  
}

.Different-Stsection h1{
    font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif;
    font-weight: 700;
    font-size: 44px !important;

}
.Trainings-We-Offer-Stsection{
    background-image: url('assets/images/Group-105.png');
  background-size: cover;
  
}

.Trainings-We-Offer-Stsection h1{
    font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif;
    font-weight: 700;
    font-size: 44px;

}

.Trainings-We-Offer p{
    font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif;
    font-size: 18px;
        text-align: center;
        font-weight: 600;
}

.Trainings-We-Offer-col {
    border-radius: 50px 0 50px 0;
    overflow: hidden;
    padding: 20px 30px 20px 30px;
    box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.3);
    width: 23% !important;
    margin-right: 2%;
    text-align: center;
}

.Trainings-We-Offer-col p {
    font-family: 'Roboto';
    font-size: 12px;
    line-height: 18px;
    color: #000000 !important;
    font-weight: 500;
}
.Trainings-We-Offer-col h5  {
    font-family: 'Roboto';
    font-size: 15px !important;
    line-height: 16px;
    font-weight: 600;
    padding-top: 20px;
}

.Trainings-We-Offer-col-Mob {
    border-radius: 50px 0 50px 0;
    overflow: hidden;
    padding: 20px 30px 20px 30px;
    box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.3);
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}

.Trainings-We-Offer-col-Mob p {
    font-family: 'Roboto';
    font-size: 12px;
    line-height: 18px;
    color: #000000 !important;
    font-weight: 500;
}
.Trainings-We-Offer-col-Mob h5  {
    font-family: 'Roboto';
    font-size: 15px !important;
    line-height: 16px;
    font-weight: 600;
    padding-top: 20px;
}

.Transport-Management-col1{
    padding: 0px 5px 0px 5px  !important;
}

.Transport-Management-col1 h4{
    font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif;
    font-size: 15px;
    line-height: 20px;
    border-color: #61C7C5;
    border-left-width: 6px;
    padding-left: 6px !important;
    border-width: 0px 0px 0px 6px;
border-style: solid;
font-weight: 600;
}
.Transport-Management-col2 h4{
    font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif;
    font-size: 16px;
    line-height: 20px;
    border-color: #61C7C5;
    border-left-width: 6px;
    padding-left: 6px !important;
    border-width: 0px 0px 0px 6px;
border-style: solid;
font-weight: 600;
}

.Transport-Management-col2{
    width: auto !important;
    padding: 20px 0px 20px 0px;
   
}
.What-We-Offer-Stsection h1{
    font-family: 'Raleway', Helvetica, Arial, Lucida, sans-serif;
    font-weight: 700;
    font-size: 46px;
}
.What-We-Offer-Stsection{
    background-image: url('assets/images/Group-105.png');
  background-size: cover;
  background-color: #f5f5f5;
  background-position: right;
  
}

.Health-and-Safety{
    background-image: url('assets/images/Group-79.png');
  background-size: cover;
  background-position: center;
 padding: 40px 0px;
}

.Health-and-Safety h1{
    font-family: 'Raleway', Helvetica, Arial, Lucida, sans-serif;
    font-weight: 700;
    font-size: 46px;
}
.Your-Futures-Partner{
    background-image: url('assets/images/Group-65-2.png');
    background-size: cover;
    background-position: bottom center;
}
.Your-Futures-Partner h1{
    font-family: 'Raleway', Helvetica, Arial, Lucida, sans-serif;
    font-weight: 700;
    font-size: 46px;
}
.Trainings-first-Stsection h4{
    padding: 20px 0px 0px 0px;
    color: #61c7c5;
    font-family: 'Raleway', Helvetica, Arial, Lucida, sans-serif;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 14px !important;
}
.Trainings-first-Stsection p{
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 14px !important;
    color: #5f5f5f !important;
    font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif;
}

.Trainings-first-Stsection h1{
    font-family: 'Raleway', Helvetica, Arial, Lucida, sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
}
.Trainings-first-button {
    margin-top: 20px;
    text-align: center !important;
    padding: 13px 0px;
    width: 227px;
    color: #fff;
    border-radius: 18px;
    background-color: rgb(58, 71, 188);
    border: none;
}

.Trainings-first-button .btn{
    padding: 10px 30px 10px 30px;
    font-size: 16px;
    
}
.Trainings-first-Getthebest p{
    font-family: 'Raleway', Helvetica, Arial, Lucida, sans-serif;
    font-size: 21px;
    color: #000000 !important;
    line-height: 24px;
    
}

/* .Trainings-pricing-Stsection{

} */
.pricing-col{
        border-radius: 30px 0 30px 0;
        overflow: hidden;
        padding-top: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.3);
    }

    .pricing-col-1{
        border-radius: 30px 0 30px 0;
        overflow: hidden;
        padding-top: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.3);
        margin-right: 0%;
    }




.days p{
    color: #fff !important;
    margin-bottom: 0px !important;
    text-align: center;
    
}
.days1 p{
    color: #fff !important;
    margin-bottom: 0px !important;
    text-align: center;
    
}
.Trainings-pricing-Stsection h5
    {
        font-family: 'Raleway', Helvetica, Arial, Lucida, sans-serif;
        font-weight: 700;
        color: #000000 !important;
        line-height: 18px;
        height: 59px;
        margin-bottom: 5px !important;
        font-size: 14px;
    }
.price p{
   
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: #3a47bc !important;
     
}


.text-inner p{
    
    font-size: 14px;
    line-height: 16px;
    height: 128px;
}
.BookNow a {
    color: #3a47bc !important;
    margin: 0;
    font-weight: 700;
}
.BookNow123 a {
    color: #3a47bc !important;
    margin: 0;
    font-weight: 700;
}
.BookNow1-1 a {
    color: #3a47bc !important;
    margin: 0;
    font-weight: 700;
}
.BookNow1-2 a {
    color: #3a47bc !important;
    margin: 0;
    font-weight: 700;
}
.BookNow2 a {
    color: #3a47bc !important;
    margin: 0;
    font-weight: 700;
}

img{
    width: 100% !important;
    object-fit: contain !important;
}

.navbar-toggler{
    background: white;
    padding: 30px;
color: white !important;
}
.navbar-toggler-icon{
    color: white !;
}

@media only screen and (max-width: 767px){
    .mb-contact-resp{
        padding: 30px 0px;
    }
    .mb-contact-resp .px-5{
padding: 0px 10px;
    }

    .mb-text{
        font-size: 40px;
    }
}


@media (min-width: 769px) and (max-width: 2100px) {
.logos-row{
    grid-template-columns: repeat(8, 1fr);
}} 

@media (max-width: 768px) {
    .logos-row {
      grid-template-columns: 1fr; /* Stack columns vertically */
    }
  }

/* For screens wider than 768px but smaller than 2100px */
@media (min-width: 769px) and (max-width: 2100px) {
    .BookNow {
        width: 30% !important;
    }
    .BookNow1-2 {
       display: none;
    }
    .BookNow1-1 {
        width: 38% !important;
    }
    .BookNow2 {
        width: 32% !important;
    }
    .row-1{
        bottom: 3px !important;
        align-items: center;
    }
    .row-2{
        bottom: -4px !important;
    }
} 

/* For screens smaller than or equal to 768px */
@media (max-width: 768px) {
    .BookNow {
        width: 50% !important;
    }
    .BookNow1-1 {
        display: none;
    }
    .BookNow1-2 {
        width: 100% !important;
        text-align: center;
    }
    .BookNow2 {
        width: 40% !important;
    }
    .pricing-col-1  {
        height: 420px;
    }
    .row-1{
        bottom: 42px !important;
    }
    .row-2{
        bottom: 4px !important;
        text-align: center !important;
    }
}

.AppealsPolicy-table table {
    border: 1px solid #0a0909;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}
.AppealsPolicy-table  th, td {
    border: 1px solid #0a0909 !important;
    padding: 8px;
    text-align: left;
}
.AppealsPolicy-table  th {
    background-color: #007ACC;
    color: white;
}
.AppealsPolicy-table  .logo-cell {
    text-align: center;
    vertical-align: middle;
    border: none;
}
 .AppealsPolicy-table .logo {
    width: 100px;
    height: auto;
}
.AppealsPolicy-table  .title-cell {
    background-color: #007ACC;
    color: white;
    font-weight: bold;
    text-align: center;
    border: none;
    padding: 10px 0;
}

.AppealsPolicy-table  tr:nth-child(even) {
    background-color: #dddddd00;
}
.Health-and-Safety .col-md-4-div{
    border: 1px solid #3a47bc;
    border-radius: 25px;
    padding: 0px 5px;
    margin: 10px;
}
.training-input{
  border-radius:5px;
  padding: 10px 10px;
  border: 1px solid #DADBDD;
  font-size: 11px;
}
.training-input-label{
   font-weight: 400;
   font-size: 14px;
}
.training-main-input{
padding: 10px;
}

p{
    font-weight: 400;
    font-size: 14px;
}

.red-color{
    color: red !important;
    font-size: 11px;
}

/*  */


table {
    /* font-family: arial, sans-serif; */
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #dddddd;
  }


/*  */
.submit-button{
    background-color: #1a7efb;
    color: #ffffff;
    border: 0px;
    padding: 10px 40px;
    border-radius:10px;
}
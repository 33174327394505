.footer  .col-md-4{
  padding: 20px 20px 20px 20px;
}
  .footer {
    
    background-color: #f5f5f5;
  
  }
.footer   p{
    
    font-size: 15px;
    line-height: 23px;
    color: #000000;
    font-weight: 500;
}

  .footer ul li {
    margin-bottom: 10px;
  }
  
  .footer a {
    color: #000;
  }
  
  .footer .copyright {
    margin-bottom: 0px;
  }
  
  .footer .copyright,
  .footer .credits {
    font-family: 'DM Sans', Helvetica, Arial, Lucida, sans-serif;
    font-size: 14px;
    
    font-weight: 600;
  color: #C2C2C2 !important;
  }
  
  .img-fluid  .bi {
    padding: 7px;
    font-size: 18px;
    width: 34px;
    text-decoration: none;
    margin: 5px 2px;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    /* border-color: #3a47bc; */
  }
  .fi-facebook {
    background: rgba(245, 245, 245, 0);
    color: #3a47bc;
}
.bottomBar{
  background-color: #3a47bc;
  padding-bottom: 0px;
  padding-top: 12px;
  padding-bottom: 12px;
}


.bottomBar p {
  font-family: 'DM Sans', Helvetica, Arial, Lucida, sans-serif;
  font-size: 14px;
 text-align: center;
  font-weight: 600;
color: #C2C2C2 !important;

}
.footer a {
  color: #C2C2C2;
}


.img-fluid  .bi {
  padding: 6px 8px 6px 8px;
  font-size: 18px;
  width: 34px;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    /* border-color: #3a47bc; */
}

.img-fluid  .bi:hover {
    opacity: 0.7;
}

.img-fluid  .bi-facebook {
  background: rgba(170, 16, 16, 0);
  color: #3a47bc !important;
}
.img-fluid  .bi-linkedin {
  background: rgba(245,245,245,0);
  color: #3a47bc  !important;
}
.img-fluid  .bi-twitter {
  background: rgba(245,245,245,0);
  color: #3a47bc  !important;
}
.img-fluid .bi-twitter-x {
    background: rgba(170, 16, 16, 0);
    color: #3a47bc !important;
  }
  .img-fluid .bi-instagram {
  background: rgba(245,245,245,0);
  color: #3a47bc  !important;
}

.img-fluid .bi-pinterest {
  background: #f5f5f500;
  color: #3a47bc   !important;
}
.img-fluid .bi-quora{
  background: #f5f5f500;
  color: #3a47bc  !important;
}
.footer-logo{
 
  
  width: 57% !important;
}
.footer-img-fluid3{
 
  width: 41% !important;
  

 
}
@media (min-width: 576px) {
	.footer-logo{
		 
		width: 25% !important;
	}
	.footer-img-fluid3{
		
    width: 25% !important;
	}
}
 

@media only screen and (max-width: 2100px) {
  .footer h3 {
    font-size: 25px !important;
    margin-bottom: 10px !important;
    font-weight: 700 !important;
  }
}
@media only screen and (max-width: 600px) {

.footer h3 {
  font-size: 26px !important;
  margin-bottom: 10px !important;
  font-weight: 700 !important;
}
}

@media only screen and (max-width: 2100px) {
  .footer .justify-content-md-normal2{
    width: 33%;
  }
  footer .justify-content-md-normal3{
    width: 33%;
  }
}
@media only screen and (max-width: 600px) {

  .footer .justify-content-md-normal2{
    width: 33%;
  }
  footer .justify-content-md-normal3{
    width: 28.5%;
  }
}

.img-fluid3{
    padding-bottom: 20px;
}


.MainFooter h5{
    
    color: #274697;
    
    line-height: 1em;
    font-size: 25px;
    font-weight: 600 !important;

}
.contactdetails {
  display: flex;
  padding-top: 14px;
  padding-bottom: 12px;
}
    .contactdetails .bi {
      padding: 7px;
      font-size: 28px;
      width: 34px;
      text-decoration: none;
      margin: 5px 2px;
    }

hr    {
      margin: 0px;
  }
.contactdetails h6{
  padding-top: 8px;
  font-size: 19px;
  line-height: 23px;
  color: #000000 !important;
  font-weight: 500;
}
.contactdetails a{
  text-decoration: none;
}
.map p{
    margin: 0;
}
.img-fluid3 {
  padding-bottom: 20px;
}